import { createAction, createAsyncAction } from 'typesafe-actions';

import Customer from '../models/Customer';
import Proposal, { ProposalStatus } from '../models/Proposal';
import { CustomerRep } from '../models/Rep';
import { IFailed } from './types';
import { MachineOption } from '../models/Machine';

enum Types {
  FETCH = '@@customers/FETCH',
  FETCH_FAILED = '@@customers/FETCH_FAILED',
  FETCH_SUCCEEDED = '@@customers/FETCH_SUCCEEDED',

  CREATE = '@@customers/CREATE',
  CREATE_FAILED = '@@customers/CREATE_FAILED',
  CREATE_SUCCEEDED = '@@customers/CREATE_SUCCEEDED',

  EDIT = '@@customers/EDIT',
  EDIT_FAILED = '@@customers/EDIT_FAILED',
  EDIT_SUCCEEDED = '@@customers/EDIT_SUCCEEDED',

  TOGGLE_CUSTOMER = '@@customers/TOGGLE_CUSTOMER',
  TOGGLE_CUSTOMER_FAILED = '@@customers/TOGGLE_CUSTOMER_FAILED',
  TOGGLE_CUSTOMER_SUCCEEDED = '@@customers/TOGGLE_CUSTOMER_SUCCEEDED',

  TOGGLE_VIEW = '@@customers/TOGGLE_VIEW',

  TOGGLE_REPS_VIEW = '@@customers/TOGGLE_REPS_VIEW',

  CREATE_REP = '@@customers/CREATE_REP',
  CREATE_REP_FAILED = '@@customers/CREATE_REP_FAILED',
  CREATE_REP_SUCCEEDED = '@@customers/CREATE_REP_SUCCEEDED',

  EDIT_REP = '@@customers/EDIT_REP',
  EDIT_REP_FAILED = '@@customers/EDIT_REP_FAILED',
  EDIT_REP_SUCCEEDED = '@@customers/EDIT_REP_SUCCEEDED',

  DELETE_REP = '@@customers/DELETE_REP',
  DELETE_REP_FAILED = '@@customers/DELETE_REP_FAILED',
  DELETE_REP_SUCCEEDED = '@@customers/DELETE_REP_SUCCEEDED',

  FETCH_PROPOSALS = '@@customers/FETCH_PROPOSALS',
  FETCH_PROPOSALS_FAILED = '@@customers/FETCH_PROPOSALS_FAILED',
  FETCH_PROPOSALS_SUCCEEDED = '@@customers/FETCH_PROPOSALS_SUCCEEDED',

  CREATE_PROPOSAL = '@@customers/CREATE_PROPOSAL',
  CREATE_PROPOSAL_FAILED = '@@customers/CREATE_PROPOSAL_FAILED',
  CREATE_PROPOSAL_SUCCEEDED = '@@customers/CREATE_PROPOSAL_SUCCEEDED',

  EDIT_PROPOSAL = '@@customers/EDIT_PROPOSAL',
  EDIT_PROPOSAL_FAILED = '@@customers/EDIT_PROPOSAL_FAILED',
  EDIT_PROPOSAL_SUCCEEDED = '@@customers/EDIT_PROPOSAL_SUCCEEDED',
  
  TOGGLE_PROPOSAL_VIEW = '@@customers/TOGGLE_PROPOSAL_VIEW',
}

interface FetchSuccess {
  customers: Customer[];
}

export const fetchCustomers = createAsyncAction(
  Types.FETCH,
  Types.FETCH_SUCCEEDED,
  Types.FETCH_FAILED
)<void, FetchSuccess, IFailed>();

export interface CreateCustomer {
  name: string;
  email?: string;
  telephone?: string;
  customerTaxNumber?: string;
  activityType?: string;
  hasVat: boolean;
  vat?: number;
  language: 'pt' | 'en' | 'fr';
  address?: string;
  city?: string;
  country?: string;
  zoneId?: number;
  company?: string;
  status?: string;
}

interface CustomerSuccess {
  customer: Customer;
}

export const createCustomer = createAsyncAction(
  Types.CREATE,
  Types.CREATE_SUCCEEDED,
  Types.CREATE_FAILED
)<CreateCustomer, CustomerSuccess, IFailed>();

export interface EditCustomer extends CreateCustomer {
  id: number,
}

export const editCustomer = createAsyncAction(
  Types.EDIT,
  Types.EDIT_SUCCEEDED,
  Types.EDIT_FAILED
)<EditCustomer, CustomerSuccess, IFailed>();

export interface ToggleCustomer {
  customerId: number;
  active: boolean;
}

export const toggleCustomer = createAsyncAction(
  Types.TOGGLE_CUSTOMER,
  Types.TOGGLE_CUSTOMER_SUCCEEDED,
  Types.TOGGLE_CUSTOMER_FAILED
)<ToggleCustomer, ToggleCustomer, IFailed>();

export const toggleCustomerView = createAction(
  Types.TOGGLE_VIEW,
)<number | undefined>();

export const toggleCustomerRepsView = createAction(
  Types.TOGGLE_REPS_VIEW,
)<number | undefined>();

interface CustomerRepSuccess {
  rep: CustomerRep;
}

export interface CreateCustomerRep {
  customerId: number;
  name: string;
  email: string;
  telephone: string;
  personFunctionId?: number;
}
export const createCustomerRep = createAsyncAction(
  Types.CREATE_REP,
  Types.CREATE_REP_SUCCEEDED,
  Types.CREATE_REP_FAILED
)<CreateCustomerRep, CustomerRepSuccess, IFailed>();

export interface EditCustomerRep extends CreateCustomerRep {
  id: number,
}

export const editCustomerRep = createAsyncAction(
  Types.EDIT_REP,
  Types.EDIT_REP_SUCCEEDED,
  Types.EDIT_REP_FAILED
)<EditCustomerRep, CustomerRepSuccess, IFailed>();

export interface DeleteCustomerRep {
  id: number;
  customerId: number;
}
export const deleteCustomerRep = createAsyncAction(
  Types.DELETE_REP,
  Types.DELETE_REP_SUCCEEDED,
  Types.DELETE_REP_FAILED
)<DeleteCustomerRep, DeleteCustomerRep, IFailed>();

interface FetchProposalSuccess {
  proposals: Proposal[];
}

export const fetchCustomerProposals = createAsyncAction(
  Types.FETCH_PROPOSALS,
  Types.FETCH_PROPOSALS_SUCCEEDED,
  Types.FETCH_PROPOSALS_FAILED
)<void, FetchProposalSuccess, IFailed>();

interface ProposalSuccess {
  proposal: Proposal;
}

export interface CreateCustomerProposal {
  commercialUserId: number;
  date: Date;
  customerId: number;
  machineId?: number;
  priceSelection?: string;
  discount?: number;
  specialPrice?: number;
  total?: number;
  opportunityObservations?: string;
  deliveryDays?: number;
  status?: ProposalStatus;
  proposalObservations?: string;
  options?: MachineOption[];
}

export const createCustomerProposal = createAsyncAction(
  Types.CREATE_PROPOSAL,
  Types.CREATE_PROPOSAL_SUCCEEDED,
  Types.CREATE_PROPOSAL_FAILED
)<CreateCustomerProposal, ProposalSuccess, IFailed>();

export interface EditCustomerProposal extends CreateCustomerProposal {
  id: number,
}

export const editCustomerProposal = createAsyncAction(
  Types.EDIT_PROPOSAL,
  Types.EDIT_PROPOSAL_SUCCEEDED,
  Types.EDIT_PROPOSAL_FAILED
)<EditCustomerProposal, ProposalSuccess, IFailed>();

export const toggleProposalView = createAction(
  Types.TOGGLE_PROPOSAL_VIEW,
)<number | undefined>();
