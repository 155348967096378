import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { DynamicValue } from '../../../../models/DynamicField';
import Machine from '../../../../models/Machine';
import Supplier from '../../../../models/Supplier';
import { commandTypes, MachineTypes } from '../sources';
import User from '../../../../models/User';

interface IProps {
  machine: Machine;
  machineStatus: DynamicValue[];
  suppliers: Supplier[];
  user: User;
}

const Info: FunctionComponent <IProps> = (properties) => {
  const { machine, machineStatus, suppliers, user } = properties;
  const {
    type,

    description,
    supplierId,
    brand,
    model,
    serialnumber,
    commandType,
    commandBrand,
    commandModel,
    buyPrice,
    installBuyPrice,
    truckBuyPrice,
    isMachineNew,
    machineYear,
    machineUsageHours,
    status,
    costsWarrantyMaterial,
    costsWarrantyTravels,
    costsWarrantyManHours,
    costsWarrantyOthers,
    costsSellMaterial,
    costsSellTravels,
    costsSellManHours,
    costsSellOthers,
    costsInvoicingMaterial,
    costsInvoicingTravels,
    costsInvoicingManHours,
    costsInvoicingOthers,
    costsOthersMaterial,
    costsOthersTravels,
    costsOthersManHours,
    costsOthersOthers,
  } = machine;

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Gerais</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Fornecedor</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(suppliers.find((s) => s.id === supplierId) || {}).name}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              {brand && (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Marca</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{brand}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {model && (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Modelo</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{model}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {serialnumber && (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Serial Number</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{serialnumber}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {description && (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Descrição</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{description}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Comando CNC</Typography>
            </Box>

            <Grid container spacing={2}>
              {type === MachineTypes.FLAT_GRINDING && commandType ? (
                <Grid item sm={12}>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Tipo de Comando</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{(commandTypes.find((ct) => ct.id === commandType) || {}).name}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {type !== MachineTypes.FLAT_GRINDING || commandType === 'cnc' ? (
                <>
                  <Grid item sm={6}>
                    <Grid container spacing={2}>
                      {commandBrand ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Comando CNC - Marca</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{commandBrand}</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item sm={6}>
                    <Grid container spacing={2}>
                      {commandModel ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Comando CNC - Modelo</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{commandModel}</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Preços e condição da máquina</Typography>
            </Box>

            <Grid container spacing={2}>
              {buyPrice && user.role !== 'tech' && user.role !== 'externaltech' && user.role !== 'commercial' ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Preço de Compra</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{buyPrice}€</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {installBuyPrice ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Preço com instalação</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{installBuyPrice}€</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {truckBuyPrice ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Preço em cima do camião</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{truckBuyPrice}€</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Máquina Nova</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{isMachineNew ? 'Sim' : 'Não'}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              {isMachineNew === false && machineYear ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Ano</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{machineYear}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              {isMachineNew === false && machineUsageHours ? (
                <>
                  <Grid item sm={6}>
                    <Typography variant="body2">
                      <b>Horas de Uso</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="body2">{machineUsageHours}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              ) : null}
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Estado</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(machineStatus.find((s) => s.id === status) || {}).value}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Custos de SAT's</Typography>
            </Box>
            
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ color: 'grey' }}>Material</TableCell>
                  <TableCell style={{ color: 'grey' }}>Deslocações</TableCell>
                  <TableCell style={{ color: 'grey' }}>Custo Técnico</TableCell>
                  <TableCell style={{ color: 'grey' }}>Outros</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Garantia</TableCell>
                  <TableCell>€ {(costsWarrantyMaterial || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsWarrantyTravels || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsWarrantyManHours || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsWarrantyOthers || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(
                    (costsWarrantyMaterial || 0) +
                    (costsWarrantyTravels || 0) +
                    (costsWarrantyManHours || 0) +
                    (costsWarrantyOthers || 0)
                  ).toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ao abrigo do valor de venda</TableCell>
                  <TableCell>€ {(costsSellMaterial || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsSellTravels || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsSellManHours || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsSellOthers || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(
                      (costsSellMaterial || 0) +
                      (costsSellTravels || 0) +
                      (costsSellManHours || 0) +
                      (costsSellOthers || 0)
                    ).toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Facturação</TableCell>
                  <TableCell>€ {(costsInvoicingMaterial || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsInvoicingTravels || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsInvoicingManHours || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsInvoicingOthers || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(
                      (costsInvoicingMaterial || 0) +
                      (costsInvoicingTravels || 0) +
                      (costsInvoicingManHours || 0) +
                      (costsInvoicingOthers || 0)
                    ).toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Outro</TableCell>
                  <TableCell>€ {(costsOthersMaterial || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsOthersTravels || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsOthersManHours || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(costsOthersOthers || 0).toFixed(2)}</TableCell>
                  <TableCell>€ {(
                      (costsOthersMaterial || 0) +
                      (costsOthersTravels || 0) +
                      (costsOthersManHours || 0) +
                      (costsOthersOthers || 0)
                    ).toFixed(2)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default Info;
